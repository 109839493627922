import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import store from "../store/index.js";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/login/callback",
    name: "callback",
    component: () => import("../views/login/callBack.vue"),
  },
  {
    path: "/login/notauthorized",
    name: "NotAuthorized",
    component: () => import("../views/login/notAuthorized.vue"),
  },
  {
    path: "/private/dashboard",
    name: "Dashboard",
    component: () => import("../views/private/dashBoard.vue"),
  },
  {
    path: "/:pathmatch(.*)*",
    redirect: "/", // Redirect home if no route is found
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  store.dispatch("fetchToken");
  if (to.fullPath === "/private/dashboard") {
    if (!store.state.user.token) {
      next({ name: "Home" });
      return;
    }
  }
  next();
});

export default router;
