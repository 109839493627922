<template>
  <div class="wrapper">
    <section class="content">
      <h1>Rangers Rambles</h1>

      <div class="info">
        <p>
          Welcome! <br />
          I created Rangers rambles primarily to document my own trades and
          thoughts. <br /><br />
          <span class="hide">
            However it turned into more than that, when people kept telling me
            how much they enjoyed reading my thoughts and how much they were
            learning just from following me. My community is a way for fellow
            traders to show their appreciation and support, whilst also
            encouraging each other as we go! <br />
          </span>
        </p>

        <div class="img"></div>
      </div>
    </section>

    <section class="gapFill"></section>

    <section class="content">
      <h2>Community</h2>

      <div class="info">
        <div class="img2"></div>

        <p>
          I get it trading can be lonely sometimes... <br /><br />
          Surround yourself with like-minded individuals who are also looking to
          grow their accounts and share their journey with you. Finding the
          right community is key to your own success, don't brush it aside.<br /><br />
        </p>
      </div>
    </section>

    <section class="gapFill"></section>

    <section class="content">
      <h2>Success</h2>

      <div class="info">
        <p>
          I am a strong believer that anyone can learn to trade. All it takes is
          the right mindset, the right tools, and the right community.
          <br /><br />
          <span class="hide">
            Which is why I have set up Rangers Rambles to help you learn how to
            trade safely and profitably. Trading isn't hard, and learning to do
            so isn't hard either, but it does take time, effort and dedication
            to succeed.
          </span>
        </p>

        <div class="img1"></div>
      </div>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: "HomeView",
  components: {},
  methods: {},
  mounted() {
    if (!sessionStorage.getItem("token")) {
      this.$store.dispatch("setState");
    }
  },
};
</script>
<style scoped>
/* Building for mobile first */
/* Custom, iPhone Retina */
@media only screen and (min-width: 320px) {
  .wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #f3f3f3;
  }
  .wrapper h1 {
    font-size: 1.8rem;
    margin-top: 60px;
    padding: 10px;
    padding-top: 60px;
    text-decoration: underline;
  }

  .content {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 40px;
    font-size: 1.2rem;
    line-height: 1.8;
  }
  .content h2 {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    font-size: 1.8rem;
    text-decoration: underline;
    padding: 10px;
    padding-left: 20px;
  }
  .info {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  .info p {
    padding: 20px;
    font-size: 1.3rem;
  }
  .img {
    min-width: 200px;
    max-width: 200px;
    min-height: 200px;
    max-height: 200px;
    border-radius: 50%;
    background-image: url("@/assets/imgs/Site/Ranger.jpg");
    background-size: cover;
    box-shadow: 0px 0px 20px 5px #1e1e1e inset;
  }
  .img1 {
    min-width: 200px;
    max-width: 200px;
    min-height: 200px;
    max-height: 200px;
    border-radius: 50%;
    background-image: url("@/assets/imgs/Site/Success.png");
    background-size: cover;
    box-shadow: 0px 0px 20px 10px #1e1e1e inset;
  }
  .img2 {
    min-width: 300px;
    max-width: 300px;
    min-height: 300px;
    max-height: 300px;
    background-image: url("@/assets/imgs/Site/Traders.png");
    background-size: cover;
  }

  .gapFill {
    min-height: 100px;
  }

  .hide {
    display: none;
  }
}

/* Extra Small Devices, Phones */
@media only screen and (min-width: 480px) {
  .img {
    min-width: 300px;
    max-width: 300px;
    min-height: 300px;
    max-height: 300px;
  }
  .img1 {
    min-width: 300px;
    max-width: 300px;
    min-height: 300px;
    max-height: 300px;
  }
}

/* Small Devices, Tablets */
@media only screen and (min-width: 768px) {
  .hide {
    display: inline-block;
  }
}

/* Medium Devices, Desktops */
@media only screen and (min-width: 992px) {
  .gapFill {
    min-height: 300px;
  }
  .guildDivs {
    flex-direction: row;
  }
  .info {
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: nowrap;
  }
  .img {
    min-width: 350px;
    max-width: 350px;
    min-height: 350px;
    max-height: 350px;
  }
  .img1 {
    min-width: 350px;
    max-width: 350px;
    min-height: 350px;
    max-height: 350px;
  }
}

/* Large Devices, Wide Screens */
@media only screen and (min-width: 1200px) {
}

/* Extra Large Devices, Wide Screens */
@media only screen and (min-width: 1400px) {
  .wrapper {
    width: 75%;
    margin: 0 auto;
  }
}
</style>
