<template>
  <nav>
    <div id="nav">
      <img
        alt="Rangers Rambles Logo"
        src="@/assets/imgs/Logo/Ranger-Rambles-Logo.png"
      />
      <button class="menu" @click="toggleMenu()">☰</button>
      <ul class="pageLink">
        <li class="routerlink" v-if="$store.state.user.token">
          <router-link to="/private/dashboard" class="navcolor">
            Home</router-link
          >
        </li>
        <li class="routerlink" v-if="!$store.state.user.token">
          <router-link to="/" class="navcolor">Home</router-link>
        </li>
        <li class="routerlink">
          <router-link
            to="#"
            v-on:click="joinSupport('https://discord.gg/UrRP7x3Xch')"
            class="navcolor"
            >Support</router-link
          >
        </li>
        <li class="routerlink">
          <router-link
            to="#"
            v-if="!$store.state.user.token"
            v-on:click="loginClick()"
            class="navcolor"
          >
            Login
          </router-link>
          <router-link
            to="#"
            v-if="$store.state.user.token"
            v-on:click="logOut()"
            class="navcolor"
          >
            Login
          </router-link>
        </li>
      </ul>
    </div>
  </nav>
  <router-view />
</template>

<script>
import { login } from "@/utils/auth";
export default {
  name: "NavBar",
  components: {},
  methods: {
    loginClick() {
      login();
    },
    logOut() {
      this.$store
        .dispatch("logout")
        .then(() => this.$router.push({ name: "Home" }))
        .then(() => {
          this.$router.go();
        });
    },
    joinSupport(url) {
      window.open(url, "_blank", "noreferrer");
    },
    toggleMenu() {
      const menu = document.querySelector(".pageLink");
      menu.style.display = menu.style.display === "flex" ? "none" : "flex";
    },
  },
};
</script>
<style>
/* Building for mobile first */
/* Custom, iPhone Retina */
@media only screen and (min-width: 320px) {
  nav {
    background-color: #1e1e1e;
    color: white;
    width: 100%;
  }
  nav button {
    width: 40px;
    border-radius: 5px;
    padding: 10px;
    font-size: 1.2rem;
    background-color: #1c7a19;
    cursor: pointer;
    border: none;
  }
  #nav {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    height: 110px;
  }
  #nav img {
    height: 110px;
  }

  .pageLink {
    display: none;
    flex-direction: column;
    background-color: #1e1e1e;
    width: 100%;
    list-style: none;
    padding: 20px;
    font-size: 1.3rem;
    z-index: 1;
  }
  .routerlink {
    width: 100%;
    padding: 10px 0;
    text-align: center;
  }
  .routerlink a {
    display: inline-block;
    text-decoration: none;
    color: white;
    vertical-align: middle;
    min-width: 100%;
    min-height: 100%;
  }
  .routerlink:hover {
    background-color: #1c7a19;
    cursor: pointer;
  }
}

/* Extra Small Devices, Phones */
@media only screen and (min-width: 480px) {
}

/* Small Devices, Tablets */
@media only screen and (min-width: 768px) {
}

/* Medium Devices, Desktops */
@media only screen and (min-width: 992px) {
  nav button {
    display: none;
  }
  .pageLink {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    font-size: 1.3rem;
    width: 75%;
  }
}

/* Large Devices, Wide Screens */
@media only screen and (min-width: 1200px) {
}

/* Extra Large Devices, Wide Screens */
@media only screen and (min-width: 1400px) {
}
</style>
