import { createStore } from "vuex";
import axios from "axios";
import config from "../utils/config.json";

export default createStore({
  state: {
    user: {
      name: "",
      discriminator: "",
      id: "",
      avatar: "",
      loggedIn: false,
      token: "",
      refreshToken: "",
      expires: 0,
    },
    guilds: [],
  },
  getters: {},
  mutations: {
    setUser(state, userInfo) {
      state.user.token = userInfo.accessToken;
      state.user.refreshToken = userInfo.refreshToken;
      state.user.expires = userInfo.expiry;
      state.user.name = userInfo.name;
      state.user.discriminator = userInfo.discriminator;
      state.user.id = userInfo.id;
      state.user.avatar = userInfo.avatar;
      state.user.loggedIn = true;
    },
    setGuilds(state, userGuilds) {
      state.guilds = userGuilds;
    },
    setSession(userInfo, userGuilds) {
      sessionStorage.setItem("name", userInfo.name);
      sessionStorage.setItem("discriminator", userInfo.discriminator);
      sessionStorage.setItem("id", userInfo.id);
      sessionStorage.setItem("avatar", userInfo.avatar);
      sessionStorage.setItem("token", userInfo.accessToken);
      sessionStorage.setItem("refreshToken", userInfo.refreshToken);
      sessionStorage.setItem("expires", userInfo.expiry);
      sessionStorage.setItem("guilds", JSON.stringify(userGuilds));
    },
    setState(state) {
      state.user.name = sessionStorage.getItem("name");
      state.user.discriminator = sessionStorage.getItem("discriminator");
      state.user.id = sessionStorage.getItem("id");
      state.user.avatar = sessionStorage.getItem("avatar");
      state.user.token = sessionStorage.getItem("token");
      state.user.refreshToken = sessionStorage.getItem("refreshToken");
      state.user.expires = sessionStorage.getItem("expires");
      state.guilds = JSON.parse(sessionStorage.getItem("guilds"));
    },
  },
  actions: {
    async login(info, code) {
      try {
        // Get Code and resend to get Token
        const getCode = new URLSearchParams({
          client_id: config.clientId,
          client_secret: config.clientSecret,
          grant_type: "authorization_code",
          code: code.toString(),
          redirect_uri: config.prodRedirectUrl, // Redirect URL
        });

        const response = await axios.post(
          "https://discord.com/api/v10/oauth2/token",
          getCode,
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        );

        const userRes = await axios.get(
          "https://discord.com/api/v10/users/@me",
          {
            headers: {
              Authorization: `Bearer ${response.data.access_token}`,
            },
          }
        );

        const userGuildRes = await axios.get(
          "https://discord.com/api/v10/users/@me/guilds",
          {
            headers: {
              Authorization: `Bearer ${response.data.access_token}`,
            },
          }
        );

        // Build passable user object
        const userInfo = {
          name: userRes.data.username,
          discriminator: userRes.data.discriminator,
          id: userRes.data.id,
          avatar: userRes.data.avatar,
          accessToken: response.data.access_token,
          refreshToken: response.data.refresh_token,
          expiry: response.data.expires_in,
          loggedIn: response.data.access_token,
        };

        // Build guilds object
        const userGuilds = userGuildRes.data;

        // Update state
        info.commit("setUser", userInfo);
        info.commit("setGuilds", userGuilds);

        //update session for persistence
        info.commit("setSession", userInfo, userGuilds);
      } catch (err) {
        console.error(err);
      }
    },

    async logOut() {
      // Build form Data
      const logoutData = new URLSearchParams({
        client_id: config.clientId,
        client_secret: config.clientSecret,
        token: sessionStorage.getItem("token"),
      });

      // revoke token
      try {
        const response = await axios.post(
          "https://discord.com/api/v10/oauth2/token/revoke",
          logoutData,
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        );
        console.log(response.data);
      } catch (err) {
        console.error(err);
      }

      // Clear session
      sessionStorage.clear();
    },

    fetchToken() {
      sessionStorage.getItem("token");
    },
  },
  modules: {},
});
